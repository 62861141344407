<template>
  <div>
    <v-container class="pb-4">
      <h1 class="mb-2">Categorias</h1>
      
      <v-card elevation="0">
          <v-row>
            <v-col
              v-for="categorie in categories"
              :key="categorie.name"
              cols="4"
            >
            <router-link class="links" :to="`/categories/${categorie.name}`">
                  <v-card>
                  <v-img
                      :src="categorie.image"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                  >
                      <v-card-title v-text="categorie.name"></v-card-title>
                  </v-img>
                  </v-card>
              </router-link>
            </v-col>
          </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Categories",
  data() {
    return {};
  },
  computed: {
    ...mapState(["categories", "saludo"]),
  },
  methods: {
    ...mapActions(["setCategories"]),
  },
  mounted() {
    this.setCategories();
  },
};
</script>
<style scoped>
.links{
    text-decoration: none;
}
</style>